<template>
  <div class="">
    <div class='cat-card'>
      <!-- <div 
        class='cat-card-head'
        :class="ar ? ['text-left'] : 'text-right'"  
      >
        <i 
          class="fa"
          :class="ar ? ['fa-arrow-left'] : 'fa-arrow-right'"
        ></i>
      </div>-->
      <div 
        class="cat-card-body mt-3 "
        :class="'text-left'"    
        :title="item.header"
      > 
        <div class="mb-4">
          <mdicon class="mdicon" height='78' width='78' :name='item.icon' />
        </div>
        <!-- <div class="text-center" v-if='$route.name == "AllCategories"'>
          <img :src='category && category.icon ? category.icon  : category.image' height='70' width='70' class="my-3 mt-4" />
        </div>
        <div class="text-center" v-else>
          <img :src='category && category.media && category.media.length > 0 ? category.icon_full_path || category.icon || category.media[0].url || placeHolderImg : category.icon_full_path || category.icon || placeHolderImg' height='70' width='70' class="my-3 mt-4" />
        </div> -->
        <div class='cat-name text-ellipsis' v-html='item.header'></div>
        <div class="text-sec">
          {{item.subheader}}
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  computed: {
    ar() {
      return this.$i18n.locale == 'ar';
    }
  }
}
</script>