<template>
  <div class='container-fluid mt-12 text-center' >
    <div class="row" :class="width == 'lg' ? 'mx-12' : 'mx-3'">
      <div class="col-12">
        <div class="row ">
          <div class="col-12 mb-5">
            <p class="about-banner pb-5 px-4">
              Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Fusce Vehicula Elit Purus, Iaculis Laoreet Lorem Bibendum Sed. Vestibulum Nec Posuere 
            </p>
            <p class="about-sub mt-5">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vehicula elit purus, iaculis laoreet lorem bibendum sed. Vestibulum nec posuere sapien. Quisque pulvinar in eros ut porttitor. Curabitur sapien risus, tincidunt ac lorem in, feugiat ultricies est. Vivamus sit amet posuere nulla, sit amet tempus libero. Duis tincidunt nibh rutrum magna interdum, eu tempus quam commodo. Donec eget accumsan nisi. Aenean tempor sem eget lacus varius, a aliquet urna eleifend.
            </p>
            <p class="about-sub mt-5">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vehicula elit purus, iaculis laoreet lorem bibendum sed. Vestibulum nec posuere sapien. Quisque pulvinar in eros ut porttitor. Curabitur sapien risus, tincidunt ac lorem in, feugiat ultricies est. Vivamus sit amet posuere nulla, sit amet tempus libero. Duis tincidunt nibh rutrum magna interdum, eu tempus quam commodo. Donec eget accumsan nisi. Aenean tempor sem eget lacus varius, a aliquet urna eleifend.
            </p>
          </div>
        </div>
        <div class="col-12 mt-5">
          <img class="about-banner-img" src='../assets/images/backgrounds/aboutbanner.png' />
        </div>

        <div class="row mt-12">
          <div class="col-12 col-xl-6 my-auto d-block d-md-none">
            <img class="side-img" src="../assets/images/backgrounds/NoPath.png" />
          </div>
          <div class="col-12 col-xl-6 text-left my-auto px-xl-5">
            <p class="about-header">Our Mission</p>
            <p class="about-sub mt-5 "> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vehicula elit purus, iaculis laoreet lorem bibendum sed. Vestibulum nec posuere sapien. Quisque pulvinar in eros ut porttitor. Curabitur sapien risus, tincidunt ac lorem</p>
            <p class="about-sub mt-5 "> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vehicula elit purus, iaculis laoreet lorem bibendum sed. Vestibulum nec posuere sapien. Quisque pulvinar in eros ut porttitor. Curabitur sapien risus, tincidunt ac lorem</p>
          </div>
          <div class="col-12 col-xl-6 my-auto d-none d-md-block">
            <img class="side-img" src="../assets/images/backgrounds/NoPath.png" />
          </div>
        </div>
        <div class="row mt-12 ">
          <div class="col-12 col-xl-6 my-auto">
            <img  class="side-img" src="../assets/images/backgrounds/NoPath2.png" />
          </div>
          <div class="col-12 col-xl-6 text-left my-auto px-xl-5">
            <p class="about-header">Our Vision</p>
            <p class="about-sub mt-5 "> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vehicula elit purus, iaculis laoreet lorem bibendum sed. Vestibulum nec posuere sapien. Quisque pulvinar in eros ut porttitor. Curabitur sapien risus, tincidunt ac lorem</p>
            <p class="about-sub mt-5 "> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vehicula elit purus, iaculis laoreet lorem bibendum sed. Vestibulum nec posuere sapien. Quisque pulvinar in eros ut porttitor. Curabitur sapien risus, tincidunt ac lorem</p>
          </div>
        </div>
        <div class="row mt-12 justify-content-around">
          <div class="col-auto " v-for='(card, index) in aboutCards' :key='index'>
            <ServiceCard :item='card'/>
          </div>
        </div>
        <div class="row mt-12">
          <div class="col-12">
            <div class="mb-8">
              <div class="cart-header ">
                FAQs
              </div>
              <div class="about-header mt-3">
                Frequently Asked Questions
              </div>
              <!-- <div class="about-sub my-3">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vehicula elit purus, iaculis laoreet lorem bibendum sed. Vestibulum nec posuere sapien. Quisque pulvinar in eros ut porttitor. Curabitur sapien risus, tincidunt ac lorem
              </div> -->
            </div>
            <div class="text-left my-2 mainBody-card" v-for='(faq, index) in FAQs' :key='index'>
              <div class="my-2 px-3" :id="'heading'+index">
                <span class="pro-header"> {{faq.question}} </span>
              </div>

              <div :id="index" class="collapse show" :aria-labelledby="'heading'+index" data-parent="acco">
                <div class=" p-3 ">
                  {{faq.answer}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ServiceCard from '@/components/misc/ServiceCard.vue';

export default {
  name: 'About',
  components: {
   ServiceCard
  },
  data() {
    return {
      aboutCards: [
        {
          icon: 'diamond-stone',
          header: 'High Quality',
          subheader: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
          icon: 'flash',
          header: 'Best Performance',
          subheader: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
          icon: 'account-group',
          header: 'Professional Team',
          subheader: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
          icon: 'handshake',
          header: 'Special Deals',
          subheader: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
      ],
      FAQs: [
        {
          question: 'Frequently Asked Questions Frequently Asked Questions ?',
          answer: 'Some placeholder content for the first accordion panel. This panel is shown by default, thanks to the  class.'
        },
        {
          question: 'Frequently Asked Questions Frequently Asked Questions ?',
          answer: 'Some placeholder content for the first accordion panel. This panel is shown by default, thanks to the  class.'
        },
        {
          question: 'Frequently Asked Questions Frequently Asked Questions ?',
          answer: 'Some placeholder content for the first accordion panel. This panel is shown by default, thanks to the  class.'
        },
      ]
    }
  },
  mounted(){
    console.log(this.$store.state.width);
    this.$loadScript('../../js/bootstrap.bundle.js')
    $('.carousel').carousel({
      interval: false,
    });
  },
  computed: {
    width() {
       return this.$store.getters.width;
    } 
  },
  methods: {
    carNext() {
      $('.carousel').carousel('next');
    },
    carPrev() {
      $('.carousel').carousel('prev');
    },
    filter() {
      $('.modal').modal('show')
    }
  }
}

</script>

<style lang="scss" scoped>

</style>